###
  Create and send POST form
  use modules: jquery, jquery.cookie (only if no assign csrf parametr)
###
window.post_form = (fields, action, csrf) ->
  if csrf isnt false
    fields['csrfmiddlewaretoken'] = csrf or $.cookie('csrf-token')
  form = $('<form>').attr(method: 'post', action: action or '')
  form.append($('<input>').attr(name: fn, value: fv)) for own fn, fv of fields
  form.css(display: 'none', position: 'absolute').appendTo($('body')).submit().remove()
  false


###
  Повертає URL документу.
  Параметри domain та anchor вказують, чи враховувати домен та якір.
###
window.get_window_url = (domain=no, anchor=no) ->
  url = window.location.href.toString()
  if not domain
    url = url.split(window.location.host)[1]
  if not anchor
    url = url.replace(/\#.*$/, '')
  url


###
  Редірект з підтримкою якорів
###
window.window_redirect = (url=null) ->
  if url in [null, '']
    r = true
  else
    r = url.split('#')[0] is (window.location.pathname + window.location.search)
    window.location.replace(url)
  window.location.reload() if r
  false
